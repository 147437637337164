<template>
  <div class="content">
    <div style="clear: both; width: 100%; height: 1px"></div>
    <div class="list" v-for="(item, index) of info" :key="index">
      <div class="public_title">
        <div class="public_title_l public_title_ls">
          <div class="public_title_name">{{ item.title }}</div>
        </div>
      </div>
      <div class="list_content">
        <div
          :class="item2.stock > 0 ? 'list_one' : 'list_one list_one2'"
          v-for="(item2, index2) of item.list"
          :key="index2"
        >
          <div class="list_one_thumb">
            <div class="img"><img v-lazy="item2.thumb" /></div>
            <div
              class="exchange_btn"
              @click="exchangeFun(item2)"
              v-if="item2.stock > 0"
            >
              点击兑换
            </div>
            <div class="setout" v-else>
              <img src="../../assets/images/integral/setout.svg" />
            </div>
          </div>
          <div class="list_one_title">{{ item2.title }}</div>
          <div class="price">
            <span>{{ item2.integral }}</span
            ><span>积分</span>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      :visible.sync="status"
      direction="ttb"
      :with-header="false"
      :destroy-on-close="true"
    >
      <integralPop :number="number" @close="closeFun" type="1"></integralPop>
    </el-drawer>
  </div>
</template>

<script>
import integralPop from "../../components/integralPop.vue";
export default {
  name: "instegralShop",
  data() {
    return {
      info: [],
      number: "",
      status: false,
      banner: {},
    };
  },
  components: {
    integralPop,
  },
  mounted() {
    this.bannerOneban();
    this.getInfo();
  },
  methods: {
    // 获取内部banner、
    bannerOneban() {
      var that = this;
      this.$api.POST(this.$face.bannerOneban, { location: 14 }, function (res) {
        that.banner = res.data;
      });
    },
    getInfo() {
      this.$api.POST(this.$face.integral_goods, {}, (res) => {
        this.info = res.data;
      });
    },

    exchangeFun(info) {
      console.log(this.$parent.headers);
      if (!this.$parent.headers.token) {
        this.$parent.$parent.loginShow();
        return;
      }

      this.$confirm(
        "请确认是否花费" + info.integral + "积分兑换" + info.title + "?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$api.POST500(
            this.$face.integral_order_create,
            {
              integral_goods_id: info.id,
            },
            (res) => {
              if (res.code == 200) {
                //this.$utile.prompt("success", "兑换成功");
                this.$parent.getUser();
                this.integral = this.integral - info.integral;
                this.number = res.data.order_no;
                this.status = true;
              } else {
                this.$alert(res.msg, "提示", {
                  confirmButtonText: "确定",
                  center: true
                });
                //this.$utile.prompt("error", res.msg);
              }
            }
          );
        })
        .catch(() => {});

      // this.$api.POST(this.$face.integral_goods,{
      //     integral_goods_id:info.id
      // },(res)=>{
      //     this.info=res.data;
      // })
    },
    toNewsFun() {
      this.$parent.newsFun();
    },
    closeFun() {
      this.status = false;
    },
    toRecord() {
      if (!this.headers.token) {
        this.$parent.loginShow();
        return;
      }
      this.$router.push({
        name: "integralRecord",
      });
    },
    toRain() {
      // if(!this.headers.token){
      //   this.$parent.loginShow();
      //   return ;
      // }
      this.$router.push({
        name: "integralIntro",
      });
    },
  },
  watch: {
    "$store.state.userInfo"(newval) {
      this.headers.token = localStorage.getItem("access_token");
      this.integral = newval.integral;
    },
  },
};
</script>

<style scoped>
.publicBox >>> .el-drawer {
  height: 100% !important;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0px rgb(0 0 0 / 0%),
    0 0px 0px 0px rgb(0 0 0 / 0%) !important;
}
.banner {
  width: 100%;
  position: relative;
  background: rgb(246, 246, 246);
  margin-bottom: 15px;
  overflow: hidden;
  min-height: 290px;
}
.banner img {
  width: 100%;
}
.banner_con {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 7%;
  width: 1008px;
  height: 83px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}
.banner_con .integral_text {
  float: left;
  margin-left: 37px;
  margin-top: 45px;
  font-size: 14px;
  color: #333;
}
.banner_con .integral_number {
  margin-top: 26px;
  font-size: 36px;
  float: left;
  color: #5857ff;
  font-weight: bold;
}
.banner_con .banner_btn {
  float: right;
  margin-right: 49px;
  margin-top: 21px;
}
.banner_con .banner_btn div {
  padding-top: 28px;
  background-repeat: no-repeat;
  float: left;
  background-position: center top;
  margin-left: 46px;
  cursor: pointer;
}
.banner_con .banner_btn .btn1 {
  background-image: url("../../assets/images/integral/get.png");
}
.banner_con .banner_btn .btn2 {
  background-image: url("../../assets/images/integral/record.png");
}
.list {
  width: 1008px;
  margin: 27px auto;
  clear: both;
}
.list_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
.list_content:after {
  content: "";
  width: 325px;
}
.list_one {
  width: 325px;
  height: 244px;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  margin-bottom: 16px;
  position: relative;
}
.list_one .setout {
  position: absolute;
  left: 0;
  top: 0;
  width: 325px;
  height: 180px;
  background-color: rgb(0, 0, 0, 0.5);
}
.list_one .setout img {
  width: 150px;
  height: 150px;
  margin: auto;
}
.list_one2 .list_one_title,
.list_one2 .price {
  opacity: 0.4;
}
.list_one .list_one_thumb {
  width: 325px;
  height: 180px;
  overflow: hidden;
  position: relative;
}
.list_one .img {
  width: 325px;
  height: 180px;
  overflow: hidden;
  position: relative;
  background: #fff;
  transition: 0.3s;
}
.list_one .list_one_thumb img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
.list_one_title {
  height: 35px;
  line-height: 40px;
  padding: 0 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.exchange_btn {
  position: absolute;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 325px;
  background: #5857ff;
  left: 0;
  bottom: -40px;
  transition: 0.5s;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.price {
  padding: 0 11px;
}
.price span {
  color: #f00;
}
.price span:nth-child(1) {
  font-size: 16px;
}
.price span:nth-child(2) {
  font-size: 12px;
}
/* .list_one:hover .img{ transform: scale(1.03);} */
.list_one:hover .exchange_btn {
  bottom: 0;
}
.content >>> .el-drawer {
  height: 100% !important;
}
</style>